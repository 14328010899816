/* eslint-disable react/jsx-filename-extension */
import React from "react";
import { graphql } from "gatsby";
import resolveImage from "@dataResolvers/resolveImage";
import resolvePageBuilder from "@dataResolvers/resolvePageBuilder";
import PageTemplate from "@pageTemplates/PersonPage";
// eslint-disable-next-line no-unused-vars
import PageBuilderFragment from "@fragments/PageBuilderFragment";
// eslint-disable-next-line no-unused-vars
import ImageFragment from "@fragments/ImageFragment";
import QueryContainer, { HeadTemplate } from "../QueryContainer";

export const query = graphql`
  query PeopleQuery($uri: [String], $siteId: [Craft_QueryArgument]) {
    craft {
      entry(uri: $uri, siteId: $siteId) {
        id
        siteId
        type: typeHandle
        title
        uid
        url
        slug
        ... on Craft_people_teachingArtists_Entry {
          # Overview
          heroImage: image0 {
            ...ImageFragment
          }
          website: link0 {
            text
            url
          }
          email: link1 {
            text
            url
          }
          phone: descriptor0
          # Content
          bio: copy0
          pageBuilder {
            ...PageBuilderFragment
          }
          # Meta
          metaTitle
          metaDescription
          metaImage {
            ...ImageFragment
          }
          tags: teachingArtistsTags {
            id
            title
            uid
            slug
          }
        }
      }
    }
  }
`;

const dataResolver = ({ entry }) => {
  const {
    type,
    heroImage,
    website,
    email,
    phone,
    tags,
    // content
    bio,
    pageBuilder,
    // Meta
    url,
    title,
    metaTitle,
    metaDescription,
    metaImage,
    slug,
  } = entry;

  const contact = [website, email, { descriptor: phone }];
  const resolvedContact = contact?.reduce((a, b) => {
    if (b.url || b.descriptor) {
      a.push(b);
    }
    return a;
  }, []);

  return {
    type,
    hero: {
      heading: title,
      image: resolveImage(heroImage || metaImage),
    },
    contact: resolvedContact,
    tags,
    bio,
    pageBuilder: resolvePageBuilder(pageBuilder),
    meta: {
      url,
      title,
      metaPageTitle: metaTitle,
      metaDescription,
      metaFeaturedImage: resolveImage(metaImage),
      slug,
    },
  };
};

export const Head = ({ data, ...rest }) => {
  const { craft } = data;
  const resolvedData = dataResolver(craft);
  const eagerUrls = [];
  return <HeadTemplate {...rest} data={resolvedData} eagerUrls={eagerUrls} />;
};

const Page = props => (
  <QueryContainer
    template={PageTemplate}
    dataResolver={dataResolver}
    queryName="PeopleQuery"
    {...props}
  />
);

export default Page;
