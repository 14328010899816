import React from "react";
import loadable from "@loadable/component";

const PageContainer = loadable(() => import("@templates/DefaultPageContainer"));
const ArtistHero = loadable(() => import("@organisms/ArtistHero"));
const ColumnCopy = loadable(() => import("@organisms/ColumnCopy"));
const PageBuilder = loadable(() => import("@templates/PageBuilder"));

const ArtistPage = ({ data }) => {
  const { uid, hero, bio, contact, tags, pageBuilder, meta } = data;

  return (
    <PageContainer meta={meta} path={uid}>
      <ArtistHero
        backLink="/opportunities-resources/schools-organizations/teaching-artist-directory"
        contact={contact}
        tags={tags}
        {...hero}
      />
      {bio && (
        <ColumnCopy
          heading="Bio"
          copy={bio}
          paddingBottom={!pageBuilder?.length}
        />
      )}
      <PageBuilder blocks={pageBuilder} />
    </PageContainer>
  );
};

export default ArtistPage;
